import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_DEVOLUCIONES_BANCOS,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosDevolucionesBancos = (data) => ({ type: RESULTADOS_DEVOLUCIONES_BANCOS, payload: data })

export const clearFilterDevolucionesBancos = () => ({ type: FILTER_CLEAR });
