import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { Link, useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'
import { getDetailedDevolucion } from '../../services/devoluciones/getDetail.service'
import { deleteDevolucion } from '../../services/devoluciones/delete.service'
import { successResponse } from '../../helpers/successResponse'


// Services

const CONSTANT = {
   title: 'Devoluciones',
   redirectListado: '/devoluciones',
   modelo: 'App\\Models\\NeuDevolucion',
   redirectUpdate: (id) => `/devoluciones/update/${id}`,
}


export const Detalle = () => {
   const [data, setData] = useState(null)
   const { id } = useParams()
   const dispatch = useDispatch()
   const redirect = useNavigate()
   const [historico, setHistorico] = useState(null)
   const [modal, setModal] = useState({ id: null, display: false, data: null })

   const fetchData = () => {
      getDetailedDevolucion(id).then(res => {
         if (successResponse(res)) {
            setData(res.data)
            return
         }

         throw res
      })
         .catch((err) => {
            console.error(err)
            const toasterContent = {
               title: 'Registro no encontrado',
               icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
         })

      historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
   }


   // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
   useEffect(() => {
      fetchData()

      return () => {
         setData(null)
      }
   }, [id, dispatch, redirect])


   // FUNCION MANEJADORA DE LA DATA DEL MODAL
   const handleModal = (data) => {
      const { id, anterior, nuevo } = data

      setModal({
         display: true,
         id,
         data: {
            anterior,
            nuevo
         }
      })
   }

   const handleDelete = (id) => {
      deleteDevolucion(id).finally(() => fetchData())
   }


   return (
      <div className='row'>
         <ModalHistorico modal={modal} fnHandleModal={handleModal} />

         <div className='col-12'>
            <div className='col mb-50'>
               <div className='d-flex gap-1'>
                  <div className='col'>
                     <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
                  </div>
                  <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
                  <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleDelete(id)} />
               </div>
            </div>

            <div className='row'>
               {data
                  ? <>
                     <div className='mx-auto col-md-6'>
                        <Accordion
                           title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
                           classes='mb-2'
                           isOpen
                        >
                           <AccordionDetailData dataKey={'ID'} value={data?.id} />
                           <AccordionDetailData dataKey={'Pedido'} value={<Link target='_blank' rel='noreferrer' to={`/pedidos/detalle/${data?.pedido_id}`}>{data?.pedido_id ?? "N/A"}</Link>} />
                           <AccordionDetailData dataKey={'Estatus'} value={data?.estatus?.nombre ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Motivo'} value={data?.motivo?.nombre ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Banco'} value={data?.banco?.nombre ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Nueva NV'} value={data?.nueva_nota_venta ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Número de cuenta'} value={data?.numero_cuenta ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Monto devuelto'} value={data?.es_monto_devuelto_parcial ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Monto devuelto parcial'} value={data?.es_monto_devuelto_parcial ? 'Sí' : 'No'} />
                           <AccordionDetailData dataKey={'Cantidad de neúmaticos'} value={data?.cantidad_neumaticos ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Cantidad de neúmaticos parcial'} value={data?.es_cantidad_neumaticos_parcial ? 'Sí' : 'No'} />
                           <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
                        </Accordion>
                     </div>

                     <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />
                  </>

                  : <CustomLoader />
               }
            </div>
         </div>
      </div>
   )
}
