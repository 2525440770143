import axios from "axios"
import { getAll } from "../../../endpoints/getAll"


export const getDetailedBancoDevolucion = async (id) => {
  try {
    if (!id) throw Error(`Missing "id" devolucion-bancos`)

    const response = await axios(getAll.devoluciones_bancos + `/${id}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })
    
    if (response.status >= 400) throw response

    return response
  } catch (err) {
    console.error(`Error fetching devolucion-bancos detail data`);
    return err
  }
}
