import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_CUENTAS_CAJA,
} from "../redux-types";

const initialState = {
   filtros: {
      busqueda: '',
   },
   resultados: null
}


export const bodegasReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case RESULTADOS_CUENTAS_CAJA:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};