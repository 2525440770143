import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_CUENTAS_CAJA,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosCuentasCaja = (data) => ({ type: RESULTADOS_CUENTAS_CAJA, payload: data })

export const clearFilterCuentasCaja = () => ({ type: FILTER_CLEAR });
