
// Components
import { DevolucionesFiltros } from "../../components/AccordionFilter/Devoluciones";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import { DevolucionesTable } from "../../components/Tables/DevolucionesTable";

const CONSTANT = {
	title: 'Devoluciones',
	path: 'devoluciones',
	create_btn: 'Nueva devolución'
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<DevolucionesFiltros />

			<DevolucionesTable />
		</div>
	);
}
