import { useEffect, useState } from "react";

// Redux
import { fireToaster } from "../../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../../components/FormInput";
import Button from "../../../components/Button";
import CustomForm from "../../../components/CustomForm";
import CustomLoader from "../../../components/CustomLoader";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Helpers
import { responseToasterContent } from "../../../helpers/toasterContent";
import { successResponse } from "../../../helpers/successResponse";
import { createFormatter, updateFormatter } from "./helpers/formFormatter";


// Services
import { getDetailedEstatusDevolucion } from "../../../services/devoluciones/estatus/getDetail.service";
import { updateEstatusDevolucion } from "../../../services/devoluciones/estatus/update.service";
import { createEstatusDevolucion } from "../../../services/devoluciones/estatus/create.service";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/devoluciones/estatus',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} estatus de devolución`,
  title: 'Devoluciones - Estatus',

  form: {
    nombre: '', // string
  }
}


export const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [form, setForm] = useState(CONSTANT.form)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE SETEA LOS DATOS PARA EL FORMULARIO DE EDICION
  useEffect(() => {
    if (!id) return

    getDetailedEstatusDevolucion(id).then(({ data }) => {
      setForm((prev) => ({
        ...prev,
        ...data
      }))
    })

  }, [id])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA/MODIFICA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    if (id) {
      updateEstatusDevolucion(id, updateFormatter({ ...form }))
        .then(res => {
          dispatch(fireToaster(responseToasterContent(res)))
          if (successResponse(res)) navigate(CONSTANT.redirectUrl)
        })
        .finally(() =>
          setIsConsulting(false)
        )

      return
    }

    createEstatusDevolucion(createFormatter({ ...form }))
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
        if (successResponse(res)) navigate(CONSTANT.redirectUrl)
      })
      .finally(() =>
        setIsConsulting(false)
      )

    return
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        {true
          ? <CustomForm size="" title={CONSTANT.componentTitle(id)}>

            <div className="row">
              <FormInput
                labelText='* Nombre'
                placeholder='Escriba un estatus'
                name='nombre'
                value={form.nombre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />
            </div>

            <Button
              type="submit"
              text={id ? 'Actualizar' : "Crear"}
              color="primary"
              disabled={isConsulting}
              icon={id ? 'Check' : "Plus"}
              onClick={(e) => handleFetch(e)}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div >
    </div >
  );
};
