import { isValidCreateForm, isValidUpdateForm } from "./validations"


export const createFormatter = (form) => {
  let formData = {
    nombre: form?.nombre, // str
    codigo: form?.codigo, // str
  }

  if (!isValidCreateForm(formData).isValid) {
    console.error({ msg: "Error en validaciones", validations: isValidCreateForm(formData) })
    return {}
  }

  return formData
}

export const updateFormatter = (form) => {
  let formData = {
    nombre: form?.nombre, // str
    codigo: form?.codigo, // str
  }

  if (!isValidUpdateForm(formData).isValid) {
    console.error({ msg: "Error en validaciones", validations: isValidCreateForm(formData) })
    return {}
  }

  return formData
}