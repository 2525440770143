export const isValidCreateForm = (form) => {
  const stringValuesArr = ['nombre']

  let result = { isValid: true }

  // required values
  if (!form?.nombre) {
    result = { ...result, isValid: false }
  }


  Object.entries(form).forEach(([k, v]) => {
    // strings
    if (stringValuesArr.includes(k) && typeof v === 'string' && !v?.length) {
      result = {
        ...result,
        [k]: `[${typeof v}], length: ${v?.length ?? 0}`,
        isValid: false
      }
      return
    }
  })

  return result
}

export const isValidUpdateForm = (form) => {
  const stringValuesArr = ['nombre']

  let result = { isValid: true }

  // required values
  if (!form?.nombre) {
    result = { ...result, isValid: false }
  }


  Object.entries(form).forEach(([k, v]) => {
    // strings
    if (stringValuesArr.includes(k) && typeof v === 'string' && !v?.length) {
      result = {
        ...result,
        [k]: `[${typeof v}], length: ${v?.length ?? 0}`,
        isValid: false
      }
      return
    }
  })

  return result
}