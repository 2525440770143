
// Components
import { CuentasCajasFiltros } from "../../components/AccordionFilter/CuentasCajas";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import { CuentasCajaTable } from "../../components/Tables/CuentasCajaTable";

const CONSTANT = {
	title: 'Cuentas de caja',
	path: 'cuentas-caja',
	create_btn: 'Crear cuenta de caja'
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>

				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<CuentasCajasFiltros />

			<CuentasCajaTable />
		</div>
	);
}
