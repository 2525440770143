import axios from "axios"
import { getOne } from "../../endpoints/getOne"


export const updateCuentaCaja = async (id, data) => {
  try {
    const response = await axios(getOne.cuenta_caja(id), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'PUT',
      data
    })

    if (response.status >= 400) throw response

    return response
  } catch (err) {
    console.error(`Error actualizando el registro de cuenta caja`);
    return err
  }
}