import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
// import Icon from "./Icon"


const Switch = ({
   labelText, name, value, margin,
   handleValue, size, sizeTablet,
   sizeDesk, isList,
   classes
}) => {
   const checkBox = useRef();

   const [isCheck, setIsCheck] = useState(value)

   const handleCheck = () => {
      setIsCheck(isCheck ? 0 : 1)

      handleValue({
         target: {
            name,
            value: isCheck ? 0 : 1
         }
      });
   }

   useEffect(() => {
      checkBox.current.checked = value;
   }, [value]);


   return (
      <div className={`d-flex ${margin ? '' : 'my-75'}  ${isList ? 'justify-content-between' : ''} ${size} ${sizeDesk} ${sizeTablet} ${classes}`}>
         <label className="form-label mt-25 mx-75" htmlFor="customSwitch">{labelText}</label>

         <div className="form-check form-check-primary form-switch">
            <input
               ref={checkBox}
               type="checkbox"
               defaultChecked={isCheck}
               className="form-check-input"
               id="customSwitch"
               name={name}
               onChange={handleCheck}
               value={isCheck ? 'on' : 'off'}
            />

            {/* Esta funcionalidad solo trae problemas ⬇ */}
            {/* {
               withIcons &&
               <label className="form-check-label" htmlFor="customSwitch10">
                  <span className="switch-icon-left">
                     <Icon icon='Check' size={14} />
                  </span>

                  <span className="switch-icon-right">
                     <Icon icon='X' size={14} />
                  </span>
               </label>
            } */}
         </div>
      </div>
   )
}

Switch.propTypes = {
   labelText: PropTypes.string,
   // value: PropTypes.number.isRequired,
   handleValue: PropTypes.func.isRequired,
   size: PropTypes.string,
   sizeDesk: PropTypes.string,
   sizeTablet: PropTypes.string,
   withIcons: PropTypes.bool,
   classes: PropTypes.string
}

Switch.defaultProps = {
   labelText: "",
   value: 1,
   size: "",
   sizeDesk: "",
   sizeTablet: "",
   withIcons: false,
   classes: "",
   handleValue: () => null
}
export default Switch