import axios from "axios"
import { getAll } from "../../../endpoints/getAll"


export const updateBancoDevolucion = async (id, data) => {
  try {
    if (!id) throw new Error('Devolucion-bancos: Missing id')

    const response = await axios(getAll.devoluciones_bancos + `/${id}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'PUT',
      data
    })

    if (response.status >= 400) throw response

    return response
  } catch (err) {
    console.error(`Error creando el registro de devolución - bancos`);
    return err
  }
}