import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "../../views/Devoluciones/Bancos/Listado";
import { Form } from "../../views/Devoluciones/Bancos/Form";
import { Detalle } from "../../views/Devoluciones/Bancos/Detalle";


export const BancosRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         <Route path='/create' element={<Form />} />

         <Route path='/update/:id' element={<Form />} />

         <Route path='/detalle/:id' element={<Detalle />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}
