// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterDevolucionesBancos, resultadosDevolucionesBancos } from '../../redux/actions/devolucionesBancos';


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Table from "../Table";
import { filtrosFormatter } from '../AccordionFilter/Devoluciones';


// Layouts
import AccordionTable from "../../layout/AccordionTable";


// Services
import { getAllBancos } from '../../services/devoluciones/bancos/getAll.service';


// Helpers


const CONSTANT = {
	permissionsPath: 'pedidos',
	reduxSelector: 'devolucionesBancos',
	pathUrl: '/devoluciones/bancos',
	title: 'Devoluciones - bancos',
	reduxClearFilters: clearFilterDevolucionesBancos,
	reduxSetResults: resultadosDevolucionesBancos,
}


export const DevolucionesBancosTable = () => {
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
	const { showing_results } = useSelector(state => state.listedResults)
	const dispatch = useDispatch()
	const { user } = useSelector(state => state.auth)


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		// console.log(url);
		dispatch(CONSTANT.reduxSetResults(null))

		getAllBancos(showing_results, filtrosFormatter(filtros), url).then(res => {
			if (res.status >= 400) {
				// dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
				dispatch(CONSTANT.reduxSetResults([]))
				return
			}

			dispatch(CONSTANT.reduxSetResults(res))
		})
	}

	// const handleDelete = (id, url) => {
	// 	deleteServicio(id).finally(() => { handleUrl(url) })
	// }

	return (
		<>
			<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
				{!response && <CustomLoader blocking={'partial'} />}

				<Table response={response} handlePagination={handleUrl}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>ID</th>
							<th>Nombre</th>
							<th>Creación</th>
							<th className="w-max">Acciones</th>
						</tr>
					</thead>

					<tbody>
						{response?.data.length
							? response.data?.map((data) => {
								return (
									<tr className="odd" key={`user-${data.id}-${data.titulo}`}>
										<td className='col-1'>{data?.id}</td>
										<td>{data?.nombre ?? "N/A"}</td>
										<td className='col-2'>{data?.created_at?.split("T")[0] || 'N/A'}</td>

										<td className='col-1'>
											<DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
												{user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
													<DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
												}

												{user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
													<DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
												}

												{/* {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
													<DropdownMenuLink text='Borrar' icon="X" onClick={() => handleDelete(data.id, response.path + '?page=' + response.current_page + `&limit=${showing_results}`)} />
												} */}
											</DropdownWrapper>
										</td>
									</tr>
								)
							})

							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}