import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../../components/Accordion'
import AccordionDetailData from '../../../components/AccordionDetailData'
import Button from '../../../components/Button'
import CustomLoader from '../../../components/CustomLoader'
import { ModalHistorico } from '../../../components/Historico/ModalHistorico'
import TablaDetalle from '../../../components/Historico/TablaDetalle'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Services
import { getDetailedMotivoDevolucion } from '../../../services/devoluciones/motivos/getDetail.service'
import { deleteMotivoDevolucion } from '../../../services/devoluciones/motivos/delete.service'


// Helpers
import { historicoData } from '../../../helpers/historicoData'
import { successResponse } from '../../../helpers/successResponse'


const CONSTANT = {
   title: 'Devoluciones - motivos',
   redirectListado: '/devoluciones/motivos',
   modelo: 'App\\Models\\NeuDevolucionesMotivos', //TODO: Preguntar a Mario por el nombre del modelo
   redirectUpdate: (id) => `/devoluciones/motivos/update/${id}`,
}


export const Detalle = () => {
   const [data, setData] = useState(null)
   const { id } = useParams()
   const dispatch = useDispatch()
   const redirect = useNavigate()
   const [historico, setHistorico] = useState(null)
   const [modal, setModal] = useState({ id: null, display: false, data: null })


   const fetchData = () => {
      getDetailedMotivoDevolucion(id).then(res => {
         if (successResponse(res)) {
            setData(res.data)
            return
         }
         
         throw res
      })
         .catch((err) => {
            console.error(err)
            const toasterContent = {
               title: 'Registro no encontrado',
               icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
         })

      historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
   }


   // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
   useEffect(() => {
      fetchData()

      return () => {
         setData(null)
      }
   }, [id, dispatch, redirect])


   // FUNCION MANEJADORA DE LA DATA DEL MODAL
   const handleModal = (data) => {
      const { id, anterior, nuevo } = data

      setModal({
         display: true,
         id,
         data: {
            anterior,
            nuevo
         }
      })
   }

   const handleDelete = (id) => {
      deleteMotivoDevolucion(id).finally(() => fetchData())
   }


   return (
      <div className='row'>
         <ModalHistorico modal={modal} fnHandleModal={handleModal} />

         <div className='col-12'>
            <div className='col mb-50'>
               <div className='d-flex gap-1'>
                  <div className='col'>
                     <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
                  </div>
                  <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
                  <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleDelete(id)} />
               </div>
            </div>

            <div className='row'>
               {data
                  ? <>
                     <div className='col-md-6 mx-auto'>
                        <Accordion
                           title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
                           classes='mb-2'
                           isOpen
                        >
                           <AccordionDetailData dataKey={'ID'} value={data?.id} />
                           <AccordionDetailData dataKey={'Nombre'} value={data?.nombre ?? 'Error'} />
                           <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
                        </Accordion>
                     </div>

                     <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />
                  </>

                  : <CustomLoader />
               }
            </div>
         </div>
      </div>
   )
}
