import axios from "axios"
import { getAll } from "../../../endpoints/getAll"


export const createEstatusDevolucion = async (data) => {
  try {
    const response = await axios(getAll.devoluciones_estatus, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data
    })

    if (response.status >= 400) throw response

    return response
  } catch (err) {
    console.error(`Error creando el registro de devolución - estatus`);
    return err
  }
}