import axios from "axios"
import { getAll } from "../../../endpoints/getAll"
import { queryParamsFormatter } from "../../../helpers/queryParamsFormatter"

const URL = getAll.devoluciones_estatus

export const getAllEstatus = async (limit = 9999, params = {}, paginationUrl = undefined) => {
  try {
    const response = await axios(`${paginationUrl ?? URL}${paginationUrl ? '&' : '?'}limit=${limit}${queryParamsFormatter(params)}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })

    if (!response.status) throw response

    return response
  } catch (err) {
    console.error(err)
    return err
  }
}