import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_BUSQUEDA } from '../../redux/redux-types'
import { fireToaster } from '../../redux/actions/toaster'
import { busquedaFilter, clearFilterCuentasCaja, resultadosCuentasCaja } from '../../redux/actions/cuentasCaja'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { toasterErrorFormatter } from '../../helpers/toasterErrorFormatter'
import { idExtractor } from '../../helpers/idExtractor'

// Services
import { getAllCuentasCajas } from '../../services/cuentasCajas/getAll.service'



const CONSTANT = {
  redirectUrl: '/cuentas-cajas',
  reduxStateSelector: 'cuentasCaja',
  title: 'Búsqueda de cuentas de caja',
  reduxClearFilters: clearFilterCuentasCaja,
  reduxSetResults: resultadosCuentasCaja,
}


export const filtrosFormatter = (filtros) => {
  let validFormat = {}

  Object.entries(filtros).forEach(([k, v]) => {
    if (k === `tipo_id` && v.length && !v.includes('NULL')) {
      validFormat = {
        ...validFormat,
        [k]: idExtractor(v, true)
      }
    }

    if (k === 'busqueda' && v.length) {
      validFormat = {
        ...validFormat,
        [k]: v
      }
    }
  })

  return validFormat
}

// export const handleSelectsData = async () => {
//   return {  }
// }

export const CuentasCajasFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const { busqueda } = filtros
  const dispatch = useDispatch()

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    setIsConsulting(true)

    getAllCuentasCajas(showing_results).then(res => dispatch(CONSTANT.reduxSetResults(res)))

    setIsConsulting(false)

    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [showing_results, dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return getAllCuentasCajas(showing_results).then(res => dispatch(CONSTANT.reduxSetResults(res)))
    }
    setIsConsulting(true)

    await getAllCuentasCajas(showing_results, filtrosFormatter(filtros))
      .then(res => {
        if (res.status >= 400) {
          dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
          dispatch(CONSTANT.reduxSetResults([]))
          return
        }

        dispatch(CONSTANT.reduxSetResults(res))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title}(${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Búsqueda'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-4'
            handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
            value={busqueda}
          />
        </>
      </ListadoHeader>
    </div>
  )
}