import { isValidCreateForm, isValidUpdateForm } from "./validations"


export const createFormatter = (form) => {
  let formData = {
    nombre: form?.nombre, // str
    codigo: Number(form?.codigo), // int
    es_stock_erp: Number(form?.es_stock_erp), // int
    estatus: Number(form?.estatus), // int
  }

  if (!isValidCreateForm(formData).isValid) {
    console.error({ msg: "Error en validaciones", validations: isValidCreateForm(formData) })
    return {}
  }

  return formData
}

export const updateFormatter = (form) => {
  let formData = {
    nombre: form?.nombre, // str
    codigo: Number(form?.codigo), // int
    es_stock_erp: Number(form?.es_stock_erp), // int
    estatus: Number(form?.estatus), // int
  }

  if (!isValidUpdateForm(formData).isValid) {
    console.error({ msg: "Error en validaciones", validations: isValidCreateForm(formData) })
    return {}
  }

  return formData
}