
// Components
import { DevolucionesEstatusFiltros } from "../../../components/AccordionFilter/DevolucionesEstatus";
import DropdownMenuLink from "../../../components/Dropdown/Link";
import DropdownWrapper from "../../../components/Dropdown/Wrapper";
import { DevolucionesEstatusTable } from "../../../components/Tables/DevolucionesEstatusTable";

const CONSTANT = {
	title: 'Devoluciones - estatus',
	path: 'devoluciones/estatus',
	create_btn: 'Nuevo motivo de devolución'
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<DevolucionesEstatusFiltros />

			<DevolucionesEstatusTable />
		</div>
	);
}
