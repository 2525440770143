import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_DEVOLUCIONES_MOTIVOS,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosDevolucionesMotivos = (data) => ({ type: RESULTADOS_DEVOLUCIONES_MOTIVOS, payload: data })

export const clearFilterDevolucionesMotivos = () => ({ type: FILTER_CLEAR });
