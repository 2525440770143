
// Components
import { BodegasFiltros } from "../../components/AccordionFilter/Bodegas";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import { BodegasTable } from "../../components/Tables/BodegasTable";

const CONSTANT = {
	title: 'Bodegas',
	path: 'bodegas',
	create_btn: 'Crear bodega'
}

export const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>

				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<BodegasFiltros />

			<BodegasTable />
		</div>
	);
}
